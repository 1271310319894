@import '../../styles/settings/colors';
@import '../../styles/tools/breakpoint';

.nav {
	position: fixed;
	top: 44px;
	right: 0;
	left: 0;
	z-index: 10;
	visibility: hidden;
	opacity: 0;
	min-height: calc(100vh - 60px - 44px);
	padding: 22px 30px 100px;
	border-top: 2px solid #f2f2f2;
	background-color: $color-white;

	@include breakpoint-up(lg) {
		top: 80px;
		right: auto;
		left: 50%;
		display: flex;
		align-items: center;
		visibility: visible;
		opacity: 1;
		width: 1590px;
		max-width: 90%;
		height: 60px;
		min-height: 0;
		padding: 0 15px;
		list-style: none;
		background: transparent;
		transform: translateX(-50%);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			height: 0;
			border-top: 60px solid #fff;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.15));
		}
	}

	@include breakpoint-up(xl) {
		padding: 0 10px;
	}
}

.nav.is-open {
	visibility: visible;
	opacity: 1;
}

.nav.is-hidden-lg {
	@include breakpoint-up(lg) {
		visibility: hidden;
		opacity: 0;
	}
}

.nav__list {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	list-style: none;

	@include breakpoint-up(lg) {
		flex-direction: row;
		align-items: center;
	}
}

.nav__list__item {
	position: relative;
	flex: 1 0 auto;
}

.nav__list__link {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 22px 0;
	font-size: 20px;
	font-weight: 500;
	line-height: 1;
	text-decoration: none;
	color: #7c7c7c;

	@include breakpoint-up(lg) {
		padding: 0 5px;
	}

	@include breakpoint-up(xl) {
		padding: 0 10px;
	}

	@include breakpoint-up(xxl) {
		padding: 0 30px;
	}
}

.nav__list__link > svg {
	width: 20px;
	height: 20px;
	margin-right: 20px;

	@include breakpoint-up(lg) {
		margin-right: 10px;
	}

	@include breakpoint-up(xl) {
		margin-right: 20px;
	}
}

.nav__list__item:first-child > .nav__list__link {
	@include breakpoint-up(lg) {
		font-size: 0;
	}
}

.nav__list__item:first-child > .nav__list__link > svg {
	fill: currentColor;

	@include breakpoint-up(lg) {
		margin-right: 0;
	}
}

.nav__list__link:hover,
.nav__list__link:focus,
.nav__list__link.active {
	color: $color-red;
}
