@import '../../styles/settings/colors';
@import '../../styles/tools/breakpoint';

.signpost {
	position: fixed;
	right: 0;
	bottom: 60px;
	left: 0;
	z-index: 4;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	background-color: $color-white;

	@include breakpoint-up(lg) {
		position: static;
		width: 250px;
		padding: 0;
		margin-top: 40px;
		background-color: transparent;
	}
}

.signpost--absolute {
	@include breakpoint-up(lg) {
		position: absolute;
		bottom: 0;
		left: 70px;
	}
}

.signpost__link {
	font-size: 18px;
	font-weight: 500;
	line-height: normal;
	text-decoration: none;
	color: $color-red;
}

.signpost__link > svg {
	width: 8px;
	height: 13px;
	font-size: 0;
	transform-origin: center;
}

.signpost__link:first-child > svg {
	margin-right: 5px;
}

.signpost__link:last-child > svg {
	margin-left: 5px;
	transform: rotate(-180deg);
}
