@import '../settings/typography';
@import 'breakpoint';

@mixin link() {
	text-decoration: underline;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

@mixin linkUnstyled() {
	text-decoration: none;
	color: inherit;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

@mixin list-unstyled() {
	padding: 0;
	margin: 0;
	list-style: none;
}

@mixin typography($style) {
	$style-props: map-get($typography-styles, $style);

	@if not map-has-key($typography-styles, $style) {
		@error "Invalid style specified! #{$style} doesn't exist. Choose one of #{map-keys($typography-styles)}";
	}

	@each $key, $value in map-get($style-props, default) {
		#{$key}: $value;
	}

	@if (map-has-key($style-props, breakpoints)) {
		@each $breakpoint in map-keys(map-get($style-props, breakpoints)) {
			@include breakpoint-up($breakpoint) {
				@each $key, $value in map-get(map-get($style-props, breakpoints), $breakpoint) {
					#{$key}: $value;
				}
			}
		}
	}
}
