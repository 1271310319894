@import '../../styles/tools/breakpoint';

.footer {
	display: none;

	@include breakpoint-up(lg) {
		position: fixed;
		bottom: 0;
		left: 50%;
		z-index: 10;
		display: block;
		width: 170px;
		font-size: 0;
		transform: translateX(-50%);
	}
}
