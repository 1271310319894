@import '../../styles/settings/colors';
@import '../../styles/tools/breakpoint';

.mounting-content {
	position: absolute;
	top: 5%;
	right: 50%;
	width: 60%;
	max-width: 300px;
	transform: translateX(50%);

	@include breakpoint-up(md) {
		max-width: none;
	}

	@include breakpoint-up(lg) {
		top: 110px;
		right: 50px;
		width: 800px;
		max-width: 50%;
		transform: translateX(0);
	}

	@include breakpoint-up(xxl) {
		top: 60px;
		left: 600px;
		width: 800px;
	}
}

.mounting-content > video {
	max-width: 100%;
	height: 150px;

	@include breakpoint-up(md) {
		height: 340px;
	}
}
