@import '../../styles/settings/colors';
@import '../../styles/tools/breakpoint';

.header {
	position: relative;
	z-index: 20;
	height: 44px;

	@include breakpoint-up(lg) {
		height: 80px;
		margin-bottom: 60px;
	}
}

.header.is-homepage {
	margin-bottom: 40px;

	@include breakpoint-up(lg) {
		margin-bottom: 60px;
	}
}

.header__inner {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 20;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 44px;
	background-color: $color-white;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);

	@include breakpoint-up(lg) {
		height: 80px;
		padding: 0 35px;
	}
}

.header__nav {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 30;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;
	box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.05);
	background-color: $color-white;

	@include breakpoint-up(lg) {
		position: static;
		padding: 0;
		background: none;
		box-shadow: none;
	}
}

.header__link {
	position: absolute;
	left: 50%;
	bottom: calc(100% + 20px);
	z-index: 30;
	display: flex;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	margin-right: 40px;
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;
	text-decoration: none;
	white-space: nowrap;
	color: $color-red;
	transform: translateX(-50%);

	@include breakpoint-up(lg) {
		position: static;
		visibility: visible;
		opacity: 1;
		transform: translateX(0);
	}
}

.header__link > svg {
	margin-left: 11px;
}

.header__title {
	position: absolute;
	top: calc(100% + 40px);
	left: 50%;
	visibility: hidden;
	opacity: 0;
	font-size: 21px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: center;
	white-space: nowrap;
	color: #333333;
	transform: translateX(-50%);

	@include breakpoint-up(lg) {
		position: static;
		flex: 1;
		visibility: visible;
		opacity: 1;
		font-size: 34px;
		transform: translateX(0);
	}
}

.header__logo {
	display: flex;
	align-items: center;
	margin-top: 4px;
	margin-left: 10px;
	font-size: 0;
	text-decoration: none;

	@include breakpoint-up(lg) {
		margin-top: 5px;
		margin-left: 0;
	}
}

.header__logo > svg {
	width: 108px;
	height: 25px;

	@include breakpoint-up(lg) {
		width: 135px;
		height: 40px;
	}
}

.header__logo,
.header__nav {
	flex: 1;
}

.header__toggle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	font-size: 0;
	border: 0;
	background: transparent;
	cursor: pointer;
	outline: 0;

	@include breakpoint-up(lg) {
		display: none;
	}
}

.header__inner.is-open {
	box-shadow: none;

	@include breakpoint-up(lg) {
		box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
	}
}

.header.is-homepage > .header__inner > .header__title {
	visibility: visible;
	opacity: 1;
}

.header__inner.is-open > .header__title,
.header.is-homepage > .header__inner.is-open > .header__title {
	visibility: hidden;
	opacity: 0;

	@include breakpoint-up(lg) {
		visibility: visible;
		opacity: 1;
	}
}

.header__inner.is-open > .header__nav > .header__link {
	visibility: visible;
	opacity: 1;
}
