@import '../../styles/tools/breakpoint';

.spinner {
	position: absolute;
	bottom: 0;
	left: 50%;
	z-index: -1;
	width: 113px;
	height: 60px;
	transform: translateX(-50%);

	@include breakpoint-up(lg) {
		width: 209px;
		height: 110px;
	}
}
