@import '../../styles/settings/colors';
@import '../../styles/tools/breakpoint';

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 40px;
	padding: 0 16px;
	font-size: 18px;
	font-weight: 300;
	font-family: inherit;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: center;
	text-decoration: none;
	color: $color-white;
	border-radius: 3px;
	border: 1px solid #fff;
	background-color: $color-red;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: $color-red-dark;
		text-decoration: none;
	}

	@include breakpoint-up(lg) {
		width: auto;
	}
}
