@import '../../styles/settings/colors';
@import '../../styles/tools/breakpoint';


.connectivity-content {
	position: relative;
	top: 20px;
	z-index: 1;
	min-width: 750px;
	min-height: 700px;

	@include breakpoint-up(md) {
		min-width: 1050px;
	}

	@include breakpoint-up(lg) {
		top: 0;
		min-height: 500px;
	}
}

.connectivity-content__item,
.connectivity-content__item > svg {
	position: absolute;
	transition: color 200ms linear;
}

.connectivity-content__item > svg > use {
	stroke: $color-red;
}

.connectivity-content__item {
	width: 180px;

	@include breakpoint-up(xl) {
		font-size: 1.25rem;
	}
}

.connectivity-content__item--antenna {
	top: -50px;
	left: calc(50% - 630px);
	text-align: right;
}

.connectivity-content__item--antenna > svg:first-of-type {
	top: 10px;
	left: 100%;
	width: 310px;
	height: 30px;
}

.connectivity-content__item--antenna > svg:last-of-type {
	top: 10px;
	left: 100%;
	width: 140px;
	height: 30px;
}

.connectivity-content__item--poe {
	top: 350px;
	left: calc(50% - 480px);
	text-align: right;
}

.connectivity-content__item--poe > svg {
	top: -70px;
	left: 98%;
	width: 260px;
	height: 90px;
	transform: rotateX(180deg);
}

.connectivity-content__item--sfp {
	top: 400px;
	left: calc(50% - 480px);
	text-align: right;
}

.connectivity-content__item--sfp > svg {
	top: -125px;
	left: 92%;
	width: 410px;
	height: 150px;
	transform: rotateX(180deg);
}

.connectivity-content__item--usb {
	top: 20px;
	right: calc(50% - 540px);
	width: 250px;
}

.connectivity-content__item--usb > svg {
	top: 7px;
	right: 100%;
	width: 210px;
	height: 35px;
}

.connectivity-content__item--voltmeter {
	top: 75px;
	right: calc(50% - 540px);
	width: 250px;
}

.connectivity-content__item--voltmeter > svg:first-of-type {
	top: -37px;
	right: 120%;
	width: 135px;
	height: 55px;
	transform: rotateX(180deg) rotateY(180deg);
}

.connectivity-content__item--voltmeter > svg:last-of-type {
	top: -37px;
	right: 100%;
	width: 225px;
	height: 55px;
	transform: rotateX(180deg) rotateY(180deg);
}
