@import '../tools/px2rem';
@import 'colors';

$typography-font-weight-values: (
	light: 300,
	regular: 400,
	medium: 500,
	bold: 700,
	black: 900,
);

$typography-line-height-values: (
	default: 1,
	condensed: 1.4,
);

$typography-font-size-base: 100%;
$typography-font-weight-base: map-get($typography-font-weight-values, regular);
$typography-font-family-primary: Lato, Helvetica, arial, sans-serif;
$typography-line-height-base: map-get($typography-line-height-values, base);

$typography-font-size-values: (
	-1: px2rem(14px),
	0: px2rem(16px),
	1: px2rem(20px),
	2: px2rem(24px),
	3: px2rem(48px),
);

$typography-styles: (
	headline1: (
		default: (
			margin-top: 0,
			margin-bottom: px2rem(20px),
			font-size: map-get($typography-font-size-values, 2),
			font-weight: map-get($typography-font-weight-values, medium),
			line-height: map-get($typography-line-height-values, condensed),
			color: $color-red,
		),
		breakpoints: (
			lg: (
				margin-bottom: px2rem(35px),
				font-size: map-get($typography-font-size-values, 3),
			),
		),
	),
	body: (
		default: (
			color: $color-black,
			font-family: $typography-font-family-primary,
			font-size: map-get($typography-font-size-values, 0),
			font-weight: map-get($typography-font-weight-values, regular),
			line-height: map-get($typography-line-height-values, base),
		),
	),
	link: (
		default: (
			color: $color-black,
			font-family: $typography-font-family-primary,
			font-size: map-get($typography-font-size-values, 0),
			font-weight: map-get($typography-font-weight-values, regular),
			line-height: map-get($typography-line-height-values, base),
		),
	),
);
