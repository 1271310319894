html {
	text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: inherit;
	font-size: px2rem(16px);
	line-height: inherit;
}

p,
ul,
ol,
dl {
	margin-top: 0;
	margin-bottom: 0;
}

figure {
	margin: 0;
}
