@import '../../styles/settings/colors';
@import '../../styles/tools/breakpoint';

.model {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: visible;
	opacity: 1;
	height: 250px;
	margin-right: -30px;
	margin-left: -30px;
	transition:
		opacity 500ms ease-in-out,
		visibility 500ms ease-in-out,
		transform 500ms ease-in-out;

	@include breakpoint-up(md) {
		height: 400px;
	}

	@include breakpoint-up(lg) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
		height: 600px;
		margin-right: 0;
		margin-left: 0;
	}
}

.model-fallback {
	position: absolute;
	top: 54%;
	left: 50%;
	z-index: 4;
	width: 40%;
	height: auto;
	transform: translate(-50%, -50%);
}

.modal-loader,
.modal-loader::after {
	border-radius: 50%;
	width: 50px;
	height: 50px;
}

.modal-loader {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 4;
	text-indent: -9999em;
	border-top: 5px solid transparentize($color-white, 0.8);
	border-right: 5px solid transparentize($color-white, 0.8);
	border-bottom: 5px solid transparentize($color-white, 0.8);
	border-left: 5px solid $color-white;
	transform: translateX(-50%) translateY(-50%) translateZ(0);
	animation: loader 1.1s infinite linear;
}

@keyframes loader {
	0% {
		transform: translateX(-50%) translateY(-50%) rotate(0deg);
	}
	100% {
		transform: translateX(-50%) translateY(-50%) rotate(360deg);
	}
}

.model-right {
	@include breakpoint-up(lg) {
		transform: translateX(25%);
	}
}

.model-bottom {
	transform: translateX(-8%) translateY(25%);

	@include breakpoint-up(lg) {
		transform: translateX(8%) translateY(15%);
	}
}

.model-top {
	position: absolute;
	min-width: 800px;
	height: 600px;
	display: flex;
	justify-content: center;
	margin-right: -30px;
	margin-left: -30px;
	transform: translateY(-24%);

	@include breakpoint-up(md) {
		min-width: 1100px;
		height: 600px;
	}

	@include breakpoint-up(lg) {
		height: 600px;
		margin-right: 0;
		margin-left: 0;
		transform: translateY(-16%);
	}
}

.model-hidden {
	visibility: hidden;
	opacity: 0;
}
