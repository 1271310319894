.site {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	margin-bottom: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.site {
		height: 100%;
	}
}

.site-content {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
}
