@import './styles/tools/breakpoint';

.wrap {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
}

.wrap-scroll {
	overflow-x: auto;
	overflow-y: visible;

	@include breakpoint-up(xl) {
		overflow: hidden;
	}
}

.wrap > svg {
	display: none;
}

.body {
	position: relative;
	z-index: 1;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	min-height: 600px;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.content {
	position: relative;
	flex-grow: 1;
	padding: 0 30px 120px;
	overflow: hidden;

	@include breakpoint-up(lg) {
		padding: 70px;
	}
}

.wrap-scroll .content {
	padding: 70px;
	overflow: auto;

	@include breakpoint-up(xl) {
		overflow: hidden;
	}
}

.text-side {
	position: relative;
	z-index: 2;

	@include breakpoint-up(lg) {
		width: 50%;
		max-width: 440px;
	}
}

#line path:last-child,
#line-corner path:last-child {
	stroke: inherit;
}
