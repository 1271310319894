.list {
	@include list-unstyled();

	margin-bottom: 40px;
	font-size: map-get($typography-font-size-values, -1);
	color: #3a3a3a;

	@include breakpoint-up(lg) {
		font-size: map-get($typography-font-size-values, 1);
	}
}

.list > li {
	margin-bottom: 10px;
	padding-left: 1em;

	&::before {
		content: "\2022";
		display: inline-block;
		font-weight: bold;
		width: 1em;
		margin-left: -1em;
		color: $color-red;
	}

	@include breakpoint-up(lg) {
		margin-bottom: 20px;
	}
}
