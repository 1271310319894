@import '../settings/breakpoints';

@mixin breakpoint-up($breakpoint, $retina: false) {
	@if (not map-has-key($breakpoint-values, $breakpoint)) or $breakpoint == 'xs' {
		@if ($retina) {
			@media (min-resolution: 192dpi) {
				@content;
			}
		}

		@else {
			@content;
		}
	}

	@else {
		@if ($retina) {
			@media (min-resolution: 192dpi) and (min-width: map-get($breakpoint-values, $breakpoint)) {
				@content;
			}
		}

		@else {
			@media (min-width: map-get($breakpoint-values, $breakpoint)) {
				@content;
			}
		}
	}
}
