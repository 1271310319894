@import '../../styles/settings/colors';
@import '../../styles/tools/breakpoint';

.home-menu {
	position: relative;
	margin-top: 40px;
	display: flex;
	justify-content: space-around;

	@include breakpoint-up(lg) {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		visibility: visible;
		opacity: 1;
		height: 600px;
		margin-top: 0;
		transition: visibility 250ms linear, opacity 250ms linear;
	}
}

.home-menu.is-hidden {
	@include breakpoint-up(lg) {
		visibility: hidden;
		opacity: 0;
	}
}

.home-menu__list {
	@include breakpoint-up(lg) {
		position: absolute;
		z-index: 3;
		top: 50%;
		transform: translateY(-50%);

		&:first-child {
			left: 5%;
		}

		&:last-child {
			right: 5%;
		}
	}
}

.home-menu__list__item {
	margin-bottom: 30px;
	backface-visibility: hidden;
	break-inside: avoid-column;

	@include breakpoint-up(lg) {
		margin-top: 50px;
		margin-bottom: 50px;
	}
}

.home-menu__link {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	text-decoration: none;
	white-space: nowrap;
	color: $color-red;

	&:hover,
	&:focus {
		color: $color-red-dark;
	}

	@include breakpoint-up(lg) {
		font-size: 22px;
	}
}

.home-menu__link > svg {
	display: block;
	flex-shrink: 0;
	width: 20px;
	height: 20px;
	margin-right: 15px;
	backface-visibility: hidden;
}

.home-menu__list:first-child > .home-menu__list__item > .home-menu__link {
	@include breakpoint-up(lg) {
		flex-direction: row-reverse;
	}
}

.home-menu__list:first-child > .home-menu__list__item > .home-menu__link > svg {
	@include breakpoint-up(lg) {
		margin-right: 0;
		margin-left: 15px;
	}
}
