html {
	height: 100%;
	font-size: $typography-font-size-base;
}

body {
	@include typography(body);

	max-width: 100%;
	height: 100%;
	margin-bottom: 0;
	overflow-x: hidden;
	background-image: url('../../images/background@2x.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	background-color: map-get($colors-gray, gray);

	@include breakpoint-up(md) {
		margin-bottom: 0;
	}
}

::selection {
	color: $color-white;
	background-color: transparentize($color-red, 0.5);
}

ul {
	@include list-unstyled();
}

p,
ul,
ol,
dl {
	@include typography(body);

	&:last-child {
		margin-bottom: 0;
	}
}

strong {
	font-weight: map-get($typography-font-weight-values, bold);
}

a {
	@include link();
	@include typography(link);
}

h1 {
	@include typography(headline1);
}
